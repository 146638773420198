<template>
  <div>
    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'User' }"
    >
      <font-awesome-icon icon="arrow-left" />
    </md-button>

    <user-form @onSubmit="onSubmit" />
  </div>
</template>

<script>
import { User } from "../../common/api/api.service";
import UserForm from "../../components/user/UserForm.vue";
import { TOOLBAR_TITLE } from "../../store/actions.types";
export default {
  components: { UserForm },
  name: "UserCreate",
  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "user_create");
  },
  methods: {
    onSubmit(user) {
      User.post(user).then(() => {
        this.$router.push({ name: "User" });
      });
    },
  },
};
</script>
